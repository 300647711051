import React, { useState, useEffect } from 'react';
//puts stuff at the top - like "we are running a sale"
const Banner = ({ content, animated }) => {
    const [path, setPath] = useState("");
    
    useEffect(() => {
        if (path !== "") {
            window.location.href = path;
        };
    });

    // sale-bar-container allows for the bounce in style, while tos-bar-container has no animations
    const parentClass = animated ? 'sale-bar-container' : 'tos-bar-container';
    let bannerContent = content.map((obj, idx) => <li onClick={() => setPath(obj["url"])} key={idx}>{obj["text"]}</li>)
    return (
        <ul className={parentClass}>
            { bannerContent }
        </ul>
    );
};

export default Banner;