import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FAQ from '../Elements/FAQ';

const Osteostrong = () => {
    const { width } = useSelector((state) => ({
        width: state.ui.width
    }));

    // scroll to top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const faqs = [
        {
            "question": "Can I participate if I don't have a genome file from 23andme or Ancestry?",
            "content": <>Send us an <a href="mailto:contact@axgen.us">email</a> and we will send you discount code for genetic testing ($150, 29% discount).</>
        },
        {
            "question": "Are my genetic data secure?",
            "content": <><ul><li>Your data is stored in a HIPAA secure location.</li></ul><a href={`${process.env.REACT_APP_BASE_URL}/privacy`}>See our Privacy Policy</a></>
        },
        {
            "question": "How well does the genetic score predict bone mineral density?",
            "content": <><ul><li>Your genetic score is an estimate of your T score, which is the standard measure of bone mineral density (BMD). The genetic score shows a 50% correlation with your T score. A T score of less than -2.5 indicates osteoporosis. Individuals with low scores showed an average decrease of -1.16 T-score units, and an increase in risk for osteoporosis of 17-fold. </li></ul></>
        },
        {
            "question": "Can I see an example of the genetic test?",
            "content": <> <li>You can see an example by visiting <a href="https://axgen.us/">AxGen</a>. </li>
                <li>Login as "osteo@axgen.us".</li>
                <li>Password: "OsteoUser123"</li></>
        },
        {
            "question": "Can I upgrade my account to view the other 32 genetic tests at AxGen?",
            "content": <>You can upgrade your account ($30) to view <a href="https://www.axgen.us/what-you-get">the other tests offered by AxGen</a> by sending us an <a href="mailto:contact@axgen.us">email</a>.</>
        },
    ];

    const osteoFAQs = faqs.map((faq, idx) => <FAQ key={idx} idx={idx} question={faq['question']} content={faq['content']} />);

    const submitted = (e) => {
        e.preventDefault();
        const formElement = document.querySelector('form');
        const data = new FormData();
        const formElementInput = formElement.querySelectorAll('input');

        for(let input of formElementInput){
            data.append(input.name, input.value);
        }
        
        // fetch('http://localhost:5000/api/order/osteostrong_uploads/', {
            fetch('https://app.axgen.us/api/order/osteostrong_uploads/', {
        // fetch(`${process.env.REACT_APP_BASE_URL}/api/order/osteostrong_uploads/`, {
                method: 'POST', 
                body: data,
            }
        ).then(response => {
            const message = response?.status === 200 ? 'Thank you. You will receive an email with the next steps.' : 'Failed to Submit';
            alert(message)

        })
        .catch(error => {
        alert('There was an error')
        });
    }

    return (
        <div className="home-parent">
            <div className="hero-area">
                <div className="osteo-img">
                    <div className="osteo-box">
                        <h1>Protect Your Bone Health</h1>
                        <p><b>Half of all women over 50 will break a bone due to osteoporosis.</b> </p>
                        <p><b>Get your free genetic screening for bone health and a free bone wellness consultation.</b></p>
                    </div>
                </div>
            </div>

            <div className="homepage-section color" id="features-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>Sign up today! </span></h1>
                        </div>
                    </div>
                    <div className="form-box">
                        <form onSubmit={ (e) => submitted(e ) } >
                            <div className="field1">
                                <div>
                                    <label htmlFor="fname">First Name:</label>
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        placeholder="First Name"
                                        required
                                    />
                                </div>
                                <label htmlFor="lname">Last Name:</label>
                                <input
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    placeholder="Last Name"
                                    required
                                />

                                <label htmlFor="email">email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="email"
                                    required
                                />
                                <label htmlFor="phone">phone:</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder="phone"
                                    required
                                />

                            </div>
                            <br></br>
                            <input
                                type="submit"
                                value="Submit"
                                id="submitBtn"
                                className="submitBtn"
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="homepage-section color" id="features-area">
                <div className="features-content">
                <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>What you get ($180 value) </span></h1>
                        </div>
                    </div>
                    <div>
                        <div className="features-text">
                            <p><b>New and unique DNA test:</b> If you’ve taken a DNA test from 23andMe or Ancestry.com, we’ll screen your genome for osteoporosis risk for free.</p>
                        </div>
                        <div className="features-text">
                            <p><b>OsteoStrong therapy:</b> Get a free initial consultation from OsteoStrong to improve your bone health and wellness. OsteoStrong is a 10 minute-a-week bone strengthening center that uses cutting edge technology to increase your bone density at any age.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-content">
                <div className="row">
                    <div className="section-title mx-auto mb-5">
                        <h2><span>What is Osteoporosis?</span></h2>
                    </div>
                </div>
                <div className="testimonials-parent">
                    <ul className="features-text">
                        <p>Low bone mineral density leads to a disease called osteoporosis that leads to broken bones from a simple fall.</p>
                        <p>Most of the 10 million people with osteoporosis do not know that they have it, but there is a simple test to find out if you have it.</p>
                        <p>Proper diet and exercise improves your bone health, and thus reduces the risk of a fragility fracture.</p>
                        <h3>If you test yourself for osteoporosis, you can take action to prevent fragility fractures before they occur!</h3>
                    </ul>
                </div>
            </div>

            <div className="homepage-section">
                <div className="features-content">
                    <div className="row">
                        <div className='section-header'>
                            <h2><span>FAQs</span></h2>
                        </div>
                        <div className="faq-area" id="faq">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="faq-main-content-all">
                                        {osteoFAQs}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>

                </div>
                {
                    width > 768 && <div className="spacer"></div>
                }
            </div>
        </div>
    );
}

export default Osteostrong;
