// titleizes blog titles
export const titleizer = (str) => {
    const specialWords = {
        'voler': 'Voler',
        'axgen': 'AxGen',
        'to': 'to',
        'and': 'and',
        'a': 'a',
        'an': 'an',
        'with': 'with'
    };
    if (str.length === 0) {
        return str;
    };
    try {
        let arr = str.split("-");
        let newStr = [];
        for (let i = 0; i < arr.length; i++) {
            let word = arr[i];
            if (specialWords.hasOwnProperty(word)) {
                newStr.push(specialWords[word]);
            } else {
                let newWord = word[0].toUpperCase() + word.slice(1);
                newStr.push(newWord);
            };
        };
        return newStr.join(" ");    
    } catch {
        return str;
    };
};
