import React from 'react';
import { Link } from 'react-router-dom';

//stuff at the bottom.
const Footer = () => {

    return (
        <div className="footer-background">
            <div className="hf-container">
                <span className="footer-sp"></span>
                <div className="footer-top">
                    <div className="footer-logo-container">
                        <Link to="/"><img src="/assets/imgs/footer-logo.png" alt="logo-alt"></img></Link>
                    </div>
                    <div className="footer-menu">
                        <ul>
                            {/* <li><Link to="/about">About</Link></li>
                            <li><Link to="/science">Science</Link></li>
                            <li><Link to="/media">Media</Link></li>
                            <li><Link to="/blog">Blog</Link></li> */}
                            <li><a href="mailto:contact@axgen.us">Contact Us</a></li>
                            <li><a href={`${process.env.REACT_APP_BASE_URL}/tos`}>Terms</a></li>
                            <li><a href={`${process.env.REACT_APP_BASE_URL}/privacy`}>Privacy</a></li>
                        </ul>
                    </div>
                    <div className="footer-social-icons">
                        <a href="https://www.facebook.com/AxGenSportsGenetics"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://twitter.com/axgeninc"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.instagram.com/axgen.sports.genetics/"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/axgen"><i className="fab fa-linkedin"></i></a>
                    </div>
                    <div className="footer-bottom">
                        <hr></hr>
                        <p>© 2023 AXGEN INC.</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;