import React, { useState, useEffect } from 'react';
import { titleizer } from '../../utils/text';
import { postContent, authors } from '../../utils/data';
import NotFoundPage from '../Elements/not-found-page';

//the posts for each blog.
const Post = ({ match }) => {
    const [post, setPost] = useState("");
    const [author, setAuthor] = useState("");
    const [content, setContent] = useState(null);
    const [img, setImg] = useState("");
    const [date, setDate] = useState("");

    useEffect(() => {
        let newPost;
        try {
            newPost = match.params.title;
        } catch(e) {
            newPost = "";
        };
        if (postContent.hasOwnProperty(newPost)) {
            // reset window at top of article whenever a new post is viewed
            window.scrollTo(0,0);
            _setPostState(newPost);
        };
    }, []);

    // attach article html if post
    useEffect(() => {
        if (post !== "") {
            const ele = document.querySelector(".post-article-text");
            ele.innerHTML = content;
        };
    }, [post]);

  //how to draw the post.  

    const _setPostState = (title) => {
        setPost(title);
        try {
            let post = postContent[title];
            setAuthor(post['author']);
            setContent(post['content']);
            setImg(post['img']);
            setDate(post['date']);
        } catch {
            return;
        };
    };

    const aboutAuthor = author !== "" ? (
        <div className="about-author">
            <div className="about-author-img">
                <img src={authors[author]['img']} alt={author}></img>
            </div>
            <div className="about-author-content">
                <p className="author-byline">{author}</p>
                <p className="author-bio">{authors[author]['bio']}</p>
            </div>
        </div>
    ) : null;

    // handles both existing and non existing posts
    const postPage = post !== "" ? (
        <div className="content-parent">
            <header className="top-hero">
                <div className="bg-white">
                    <img className="blog-view-img" src={img} alt={post}></img>
                </div>
            </header>
            <div className="content-container">
                <div className="post-header-text">
                    <h1>{titleizer(post)}</h1>
                    <div className="byline">
                        <p>{author}</p><span>-</span><p>{date}</p>
                    </div>
                </div>
                <div className="post-article-text"></div>
                <div className="post-footer">
                    { aboutAuthor }
                </div>
            </div>
        </div>
    ) : <NotFoundPage isBlogPost={true} />;

    return postPage;
};

export default Post;
