import { UPDATE_WIDTH } from '../actions/ui-actions';

// default state of the ui
const defaultState = {
    width: null
};

const uiReducer = (state = defaultState, action) => {
    Object.freeze(state);
    
    switch(action.type) {
        case UPDATE_WIDTH:
            const newState = Object.assign({}, state);
            newState.width = action.width;
            return newState;
        default:
            return state;
    };
};

export default uiReducer;