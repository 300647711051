import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { team, sab, partners } from '../../utils/data';
import { addExtraLi } from '../../utils/style-utils';
import FAQ from '../Elements/FAQ';

//FAQs appear at bottom of page
const faqs = [
    {
        "question": "What are your privacy policies?",
        "content": <><ul><li>We will not share your individual-level information without your explicit consent with any third party, including your coach, trainer, team or league.</li><li>We strongly support the Genetic Information Nondiscrimination Act (GINA) and other similar laws that protect individuals from being discriminated against based on their genetics.</li><li>We do not provide information to law enforcement unless we are required to comply with a valid subpoena or a court-ordered request.</li></ul><a href={`${process.env.REACT_APP_BASE_URL}/privacy`}>See our Privacy Policy</a></>
    },
    {
        "question": "What services do you provide?",
        "content": <><ul><li>Process your DNA sample and analyze your genetic data with our proprietary algorithms.</li><li>Comprehensive report with genetic insights for injury risks and performance markers as well as recommendations and actions based on your personal insights.</li><li>An exercise video library for injury prehabilitation based on your genetic insights.</li><li>A consultation with an AxGen Sports Genetics Advisor to help you better understand your genetic results.</li><li>A consultation with an AxGen Sports Genetics Trainer to tailor your training program to your unique genetic profile.</li></ul></>
    },
    {
        "question": "How do I see my AxGen Report?",
        "content": <>You can view your personal results dashboard via our secure <a href={`${process.env.REACT_APP_BASE_URL}`}>web application</a> on any internet-connected device. Your AxGen Report is broken down into two sections: Injuries and Performance Markers. Each category in these sections displays your genetic results, recommendations on specific actions to take and video exercises.</>
    },
    {
        "question": "Why do I need to activate my AxGen DNA Kit and how do I activate it?",
        "content": <>You need to activate your kit so we can link the correct sample/results from our lab to your AxGen account. You <a href={`${process.env.REACT_APP_BASE_URL}/activate`}>activate</a> your kit by navigating to the AxGen website, logging into your account and inputting your kit’s unique barcode.</>
    },
    {
        "question": "How do you protect my genetic information?",
        "content": <>We put the security of our customer's genetic data first. We have numerous security measures in place to prevent unauthorized access. We protect your identity by disconnecting personal identifying information from genetic information. We will not share your personal genetic data with any third party without your explicit consent.</>
    },
];


const About = () => {
    // scroll to top on mount
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);


    // presentational
    const theSAB = Object.keys(sab).map((name, i) => {
        return (
            <li
                key={i}>
                <div className="avatar-container">
                    <img src={sab[name]["url"]} alt={name}></img>
                </div>
                <Typography variant="h3">{name}</Typography>
                <Typography variant="h5">{sab[name]["title"]}</Typography>
            </li>
        );
    });

    const theTeam = Object.keys(team).map((name, i) => {
        return (
            <li
                key={i}>
                <div className="avatar-container">
                    <img src={team[name]["url"]} alt={name}></img>
                </div>
                <Typography variant="h3">{name}</Typography>
                <Typography variant="h5">{team[name]["pos"]}</Typography>
                <p className="bio">{team[name]["des"]}</p>
            </li>
        )
    });


    // renders the list items for the partners
    const ourPartners = Object.keys(partners).map((partner, idx) => {
        const partnerObj = partners[partner];
        return (
            <li key={idx}>
                <a target="_blank" rel="noopener noreferrer" href={partnerObj["url"]}>
                    <img  src={partnerObj["img"]} alt=""></img>
                </a>
                <a target="_blank" rel="noopener noreferrer" href={partnerObj["url"]}>{partner}</a>
            </li>
        );
    });
    if (ourPartners.length > 0) addExtraLi(ourPartners, 3);

    const axgenFAQs = faqs.map((faq, idx) => <FAQ key={idx} idx={idx} question={faq['question']} content={faq['content']} />);
    
    return (
        <div className="content-parent">
            <header className="top-hero">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1>AXGEN</h1>
                        <p>Genetic Knowledge for All Athletes</p>
                    </div>
                </div>
            </header>
            <div className="content-container">
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>MISSION</span></h2>
                        <p>AxGen is transforming the sports world by leveraging actionable genetic insights to personalize strength training, so as to reduce injury and empower athletes to achieve their peak potential.</p>
                    </div>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>Leadership</span></h2>
                    </div>
                    <div className="leadership-section">
                        <ul className="team-list">
                            {theTeam}
                        </ul>
                    </div>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>ADVISORY BOARD</span></h2>
                    </div>
                    <div className="team-section">
                        <ul className="team-list">
                            {theSAB}
                        </ul>
                    </div>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>VALUES</span></h2>
                    </div>
                        <ul className="values-list">
                            <li>
                                <div className="another-container">
                                    <div className="value-icon-container">
                                        <i className="fas fa-chart-line"></i>
                                    </div>
                                </div>
                                <div className="value-list-content">
                                    <h3><span>Actionable Results</span></h3>
                                    <p>Genetic test results indicate a substantial difference in either injury risk or nutrition. The genetic tests are actionable - training regimens can reduce those risks.</p>
                                </div>
                            </li>
                            <li>
                            <div className="another-container">
                                <div className="value-icon-container">
                                    <i className="fas fa-flask"></i>
                                </div>
                            </div>
                                <div className="value-list-content">
                                    <h3><span>Scientific Rigor</span></h3>
                                    <p>We emphasize scientific rigor, validity, and reproducibility.</p>
                                </div>
                            </li>
                            <li>
                            <div className="another-container">
                                <div className="value-icon-container">
                                    <i className="fas fa-trophy"></i>
                                </div>
                            </div>
                                <div className="value-list-content">
                                    <h3><span>Athletic Advantage</span></h3>
                                    <p>We provide a new edge for athletes and teams to compete at the highest level.</p>
                                </div>
                            </li>
                        </ul>
                        <ul className="values-list pt-0">
                            <li>
                            <div className="another-container">
                                <div className="value-icon-container">
                                    <i className="fas fa-book"></i>
                                </div>
                            </div>
                                <div className="value-list-content">
                                    <h3><span>Knowledge is Power</span></h3>
                                    <p>Knowledge of genetic risk factors allows an athlete to take preventative actions.</p>
                                </div>
                            </li>
                            <li>
                                <div className="another-container">
                                    <div className="value-icon-container">
                                        <i className="fas fa-dna"></i>
                                    </div>
                                </div>
                                <div className="value-list-content">
                                    <h3><span>No Genetic Discrimination</span></h3>
                                    <p>We only use genetics to benefit athletes, not for genetic discrimination.</p>
                                </div>
                            </li>
                        </ul>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>OUR PARTNERS</span></h2>
                    </div>
                    <ul className="our-partners">
                        { ourPartners }
                    </ul>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>FAQs</span></h2>
                    </div>
                    <div className="faq-area" id="faq">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="faq-main-content-all">
                                    { axgenFAQs }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default About;
