import React from 'react';
import { Link } from 'react-router-dom';

//IF someone clicks on page that does not exist.
const NotFoundPage = ({ isBlogPost=false }) => {
    const homePageLink = <p>Go to <Link to="/">AxGen's Homepage</Link></p>;

    return (
        <div className="content-parent">
            <div className="content-container">
                <div className="not-found-content row justify-content-center">
                    <div>
                        <img alt="" src="/assets/imgs/x-img.png"></img>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>
                            <h1>Sorry, page not found!</h1>
                            { isBlogPost ? 
                                <div>
                                    <p className="mb-0">Go to <Link to="/blog">AxGen's Blog</Link></p>
                                    { homePageLink }
                                </div>
                                : homePageLink
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default NotFoundPage;