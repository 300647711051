import React, { useEffect } from "react";
import GoogleAnalytics from "react-ga";

//This tracks our pages with google analytics.  
const googleAnalyticsID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

if (googleAnalyticsID) { 
    GoogleAnalytics.initialize(googleAnalyticsID);
};

// higher-order component that wraps pages in google analytics tracker and returns the pages
// HOC is the component that is returned
const GATracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        if (googleAnalyticsID) {
        GoogleAnalytics.set({
            page,
            ...options,
        });
        GoogleAnalytics.pageview(page);
    };
  };

  const HOC = (props) => {

    //initializes tracker on the page onload
    // fires only when the path or the url params change
    useEffect(() => {
        const page = props.location.pathname + props.location.search;
        trackPage(page);
    }, [props.location.pathname, props.location.search]);

    return (
        <WrappedComponent {...props} />
    );
  };

  return HOC;
};

export default GATracker;