import React, { useState, useEffect } from 'react';
import { trustedBy, testimonials } from '../../utils/data';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


//number of tests
const numInjuryTests = process.env.REACT_APP_INJURY_TESTS;
const numHealthTests = process.env.REACT_APP_HEALTH_TESTS;
const numPerformanceTests = process.env.REACT_APP_PERFORMANCE_TESTS;

// The Home page from www.axgen.us. 
const Home = () => {
    const { width } = useSelector((state) => ({
        width: state.ui.width
    }));

    const [selected, selectAthlete] = useState("Kris Mendoza");

    // scroll to top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // creates the tabs that control what one can view at once
    // the selectAthlete function sets the selected athlete as the state, allowing the react to key into the object and dynamically render the relevant info
    // for the tabs, only the first names are used, to reduce space, improve aesthetics, and not be too repetative (full name is already shown if one clicks on a tab)
    const athletesList = Object.keys(testimonials).map((name, idx) => {
        const nameArr = name.split(" ");
        return (
            <li className={selected === name ? "li-active" : "li-inactive"}
                key={idx}
                onClick={() => selectAthlete(name)}
            ><h3>{nameArr[0]}</h3></li>
        )
    });

    //"trusted by athletes" in data.js

    const trustedByAthletes = trustedBy.map((el, idx) => (
        <li key={idx}><img className="mb-0" src={el["url"]} alt={el["title"]}></img></li>
    ));

    return (
        <div className="home-parent">
            <div className="hero-area">
                <div className="health-img">
                    <div className="hero-box">
                        <h1>Personalized Medicine</h1>
                        <p>AxGen’s genetic tests enables medical practioners to offer personalized, predictive, and preventative care for their patients. In addition to tests for rare diseases  (sickle cell trait, Alzheimer's etc.), AxGen has tests for chronic diseases (osteoporosis, coronary heart disease, type 2 diabetes etc.) that are exclusive to AxGen. AxGen empowers you to provide care that is proactive and wellness focused.  </p>
                    </div>
                </div>
                <div className="img-filter"></div>
            </div>

            <div className="homepage-section" id="our-partners-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>AxGen Health Introduction</span></h1>
                            
                            <iframe src="https://www.youtube.com/embed/tWCG1D7Ult8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>
                    </div>
                    <div className="testimonials-parent">
                        <ul className="trusted-by">
                            {/* { trustedByAthletes } */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="homepage-section color" id="features-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>The AxGen Approach</span></h1>
                        </div>
                    </div>
                    <div>
                        <img className="feat-img" alt="" src="./assets/imgs/healthbannercrop.png"></img>
                        {/* <div className="features-text">
                            <h2>PERSONALIZED FOR YOUR PATIENTS</h2>
                            <p>Genetics greatly influence the risk levels for various cancers, disease, and other health conditions. Don’t just treat syptoms and diseaes once they are present, make a healthcare plan that is personlized to your patients' own unique DNA.</p>
                        </div> */}
                        <div className="features-text">
                            <h2>CUTTING EDGE SCIENCE</h2>
                            <p>Revolutionary new tests called "Polygenic Risk Scores" (PRS's) have greatly enhanced the predictive power of genetics. PRS's are calculated by combining the effects of multiple genetic variants, which can provide a more accurate assessment of risk than a single variant, especially for complex diseases and conditions. Many of these <Link to="/what-you-get-health">tests</Link> are exclusive to AxGen. </p>
                        </div>
                        <div className="features-text">
                            <h2>ACTIONABLE</h2>
                            <p>The true value of an AxGen test comes from pairing our DNA insights with your expertise as an MD, and your knowledge of your patients' medical history. Use genetic warning signs to mitigate risk for specific diseases with targeted lifestyle changes and personalized treatment.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homepage-section" id="tests-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>AxGen Tests</span></h1>
                        </div>
                    </div>
                    {width > 767 ? (
                        <div className="tests-section d-flex">
                            <div className="tests-text">
                                <h2>KNOW YOUR RISKS</h2>
                                <p>AxGen tests for risk for {numHealthTests} health conditions and diseases (including osteoporosis, coronary heart disease, and type 2 diabetes).</p>
                                <p className="mb-0"><strong>Click <Link to="/what-you-get-health">here</Link> to see our tests.</strong></p>
                            </div>
                            <div className="img-container">
                                <img alt="" src="./assets/imgs/osteo-new.png"></img>
                            </div>
                        </div>
                    ) : (
                        <div className="tests-section">
                            <h2 className="text-center">KNOW YOUR RISKS</h2>
                            <img alt="" src="./assets/imgs/osteo-new1.png"></img>
                            <img alt="" src="./assets/imgs/healthbannercrop.png"></img>
                            <p>AxGen tests for {numInjuryTests} sports injuries (including ACL and PCL injury, Achilles tendon rupture, stress fracture, and concussion) and {numPerformanceTests} nutrition and performance markers (including ibuprofen sensitivity and testosterone level).</p>
                            <p className="mb-0"><strong>Click <Link to="/what-you-get-health">here</Link> to discover more.</strong></p>
                        </div>
                    )
                    }
                </div>
            </div>
            {/* <div className="homepage-section color" id="testimonials-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>Testimonials</span></h1>
                        </div>
                    </div>
                    <div className="testimonials-parent">
                        <ul className="image-selection-menu">
                            {athletesList}
                        </ul>
                        <div className="testimonial-content">
                            <div className="testimonial-img-container">
                                <img src={testimonials[selected]["img"]} alt=""></img>
                            </div>
                            <div className="testimonial-text">
                                <div className="speech-bubble">
                                    <p>{testimonials[selected]["text"]}</p>
                                    {testimonials[selected]["text2"] ? <p>{testimonials[selected]["text2"]}</p>
                                        : null
                                    }
                                    <h3>{selected}</h3>
                                    <p className="testimonial-title">{testimonials[selected]["title"]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="homepage-section" id="process-section">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                        <h1><span>Our Process - Health Clinics</span></h1>
                        </div>
                    </div>
                    <div className="works-container">
                        <div className="line-img"></div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-img-1.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>1</span>
                            </div>
                            <div className="item-p">
                                <h2>GET STARTED</h2>
                                <p> <a href="mailto:contact@axgen.us?subject=Partnership Inquiry">CONTACT</a> AxGen to meet with us about using AxGen Health in your clinic. We’ll provide you with all the necessary tools and knowledge to start using AxGen at your clinic. Your AxGen account will be your hub for ordering and viewing patient results. </p>
                            </div>
                        </div>
                        <div className='right-works'>
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-2.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <span>2</span>
                                <hr></hr>
                            </div>
                            <div className="item-p">
                                <h2>COLLECT SAMPLES</h2>
                                <p>Kits will be sent directly to your patients. They will collect a saliva sample and mail the kit back with our prepaid label. Patients that already have a genotype file can upload it directly to AxGen.
</p>
                            </div>
                        </div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-3.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>3</span>
                            </div>
                            <div className="item-p">
                                <h2>PROCESSING</h2>
                                <p>The samples are genotyped and the data are analyzed to generate insights and actionable results.</p>
                            </div>
                        </div>
                        <div className='right-works'>
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/pro-cess-4.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <span>4</span>
                                <hr></hr>
                            </div>
                            <div className="item-p">
                                <h2>DISCOVER</h2>
                                <p>The personalized report will contain 1) genetic risks for rare Mendelian and chronic complex diseases and 2) genetic insights about injury risks and performance/nutrition markers. To see all of the tests we offer, visit our <Link to="/what-you-get-health">TESTS</Link> page.</p>
                            </div>
                        </div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-5.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>5</span>
                            </div>
                            <div className="item-p">
                                <h2>TREAT</h2>
                                <p>The results dashboard allows you to prepare a personalized testing and treatment plan for your patient.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homepage-section">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>Partnering with AxGen Health</span></h1>
                        </div>
                    </div>
                    <div>
                        <p>AxGen has formed partnerships with primary care doctors, orthopedists, and concierge medicine clinics to provide their patients' with a preventative and predictive approach to healthcare.</p>
                        <p>Email <a href="mailto:contact@axgen.us?subject=Partnership Inquiry">contact@axgen.us</a> to book a meeting about using AxGen Health in your medical practice.</p>
                    </div>

                </div>
                {
                    width > 768 && <div className="spacer"></div>
                }
            </div>
        </div>
    );
}
export default Home;