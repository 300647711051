import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useState } from 'react';
import Home from './components/Home/home';
import NavBar from './components/Nav/navbar';
import About from './components/About/about';
import Footer from './components/Nav/footer';
import Banner from './components/Elements/banner';
import Media from './components/Media/media';
import MediaHealth from './components/Media/media-health';
import Blog from './components/Blog/blog';
import BlogHealth from './components/Blog/blog-health';
import Post from './components/Blog/post';
import WhatYouGet from './components/What-You-Get/what-you-get';
import WhatYouGetHealth from './components/What-You-Get/what-you-get-health';
import Osteostrong from './components/Osteostrong/osteostrong';
import NotFoundPage from './components/Elements/not-found-page';
import Science from './components/science/science';
import Sports from './components/Sports/sports';
import Health from './components/Health/health';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './sass/main.scss';
import GATracker from './components/Util/ga-tracker';

//The router. Define the routes of the app.
const App = () => {
  // local states
  const [banner] = useState(false);  //by changing true to false we can toggle the visibility of the banner
  // banner contents ; for multiple banners, import function from utils
  const [bannerContent] = useState([{ "url": 'https://uwba.org/', "text": '10% of all sales will be donated to the United Way Bay Area COVID-19 fund to support local families in need.' }]);

  // define the navbar links wtihin axgen.
  return (
    <div className="app-parent">
      <BrowserRouter>
        <header className="header-parent">
          { banner ? <Banner content={bannerContent}
      animated={false}/> : null }
          <NavBar/>
        </header>
        <div className={ banner ? "content-wrapper-bannered" : "content-wrapper-bannerless" }>
          <Switch>
            <Route exact path="/" component={GATracker(Home)}/>
            <Route exact path="/home" component={GATracker(Home)}/>
            <Route exact path="/about" component={GATracker(About)}/>
            <Route exact path="/science" component={GATracker(Science)}/>
            <Route exact path="/media" component={GATracker(Media)}/>
            <Route exact path="/media-health" component={GATracker(MediaHealth)}/>
            <Route exact path="/blog" component={GATracker(Blog)}/>
            <Route exact path="/blog-health" component={GATracker(BlogHealth)}/>
            <Route exact path="/blog/:title" component={GATracker(Post)}/>
            <Route exact path="/what-you-get" component={GATracker(WhatYouGet)}/>
            <Route exact path="/what-you-get-health" component={GATracker(WhatYouGetHealth)}/>
            <Route exact path="/osteostrong" component={GATracker(Osteostrong)}/>
            <Route exact path="/sports" component={GATracker(Sports)}/>
            <Route exact path="/health" component={GATracker(Health)}/>
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <footer className="footer-parent">
          <Footer/>
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
