import { withRouter } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch } from 'react-redux';
import { updateWindowWidth } from '../../actions/ui-actions';

// Add or delete lines to control which tabs appear in the navbar for /home, /health and /sports pages.
const getNavItems = (page) => {
    let navItems = {};
    switch(page) {
        case '/health':
            navItems = {
                "Sports": "/sports",
                "Health": "/health",
                "Tests": "/what-you-get-health", 
                // "Science": "/science",
                "Health Media": "/media-health",
                // "Media": "/media",
                "Blog": "/blog-health",
                "About": "/about"
            };
            break;
        case '/sports':
            navItems = {
                "Sports": "/sports",
                "Health": "/health",
                "Tests": "/what-you-get",
                "Science": "/science",
                "Sports Media": "/media",
                "Blog": "/blog",
                "About": "/about"
            };
            break;
        case '/health':
            navItems = {
                "Sports": "/sports",
                "Health": "/health",
                "Tests": "/what-you-get-health", 
                // "Science": "/science",
                // "Media": "/media", 
                "Blog": "/blog-health",
                "About": "/about"
            };
            break;
        default: // This covers home (/)
            navItems = { 
                "Sports": "/sports",
                "Health": "/health",
                // "Tests": "/what-you-get",
                // "Science": "/science",
                // "Media": "/media",
                // "Blog": "/blog",
                "About": "/about"
            };
    }
    return navItems;
};

export const NavBar = ({ history }) => {
    // local state
    const [path, setPath] = useState('');
    const currentPage = history?.location?.pathname || '/';
    const navItems = getNavItems(currentPage);
    const [width, adjustWidth] = useState(window.innerWidth);  //sets to the width of the window

    
    // the following handles dynamic window width
    // dispatches
    const dispatch = useDispatch();
    const updateWidthDispatch = useCallback(
        (width) => dispatch(updateWindowWidth(width)),
        [dispatch]
    );
    // functions
    // adjusts the width local state every time the width changes allowing for dynamic resizing
    const _updateWidth = () => {
        let newWidth = window.innerWidth;
        if (newWidth !== width) {
            adjustWidth(newWidth);
        };
    };
    // fires on mount and whenever the width state changes
    // uses event listenders to listen to resizing
    useEffect(() => {
        if (width) {
            updateWidthDispatch(width);
        };
        const resize = () => _updateWidth();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [width]);


    // handles redirect
    useEffect(() => {
        if (path !== '') {
            window.location.href = path;
        }
    }, [path]);
    

    // presentational functions and elements

    // generates navigation bar items
    const _generateItems = (itemType) => {
        if (itemType === 'mobile') {
            return Object.keys(navItems).map((page, idx) => (
                <Dropdown.Item key={idx} onClick={() => history.push(navItems[page])} active={history.location.pathname === navItems[page]}>{page}</Dropdown.Item>
            ));
        } else {
            return Object.keys(navItems).map((page, idx) => (
                <Link key={idx} className={history.location.pathname === navItems[page] ? 'nav-link active' : 'nav-link'} to={navItems[page]}><span>{page}</span></Link>
            ));
        };
    };

    // determines the navbar type based on window width
    // css styles back up this determination by rendering the specific bar based on window width, removing the need to rerender entire page because of width state change
    let navbar;
    if (width < 992) {
        let navItems = _generateItems("mobile");
        navbar = 
            <Dropdown className="site-menu-mobile">
                <Dropdown.Toggle as="li" className="nav-item d-flex h-100">
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { navItems }
                    <Dropdown.Item onClick={() => setPath(`${process.env.REACT_APP_BASE_URL}/login`)}>Login</Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setPath(`${process.env.REACT_APP_BASE_URL}/register`)}>Register</Dropdown.Item> */}
                    <Dropdown.Item onClick={() => history.push('/what-you-get')}>Buy</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
    } else {
        let navItems = _generateItems("regular");
        navbar =
            <nav className="site-menu">
                <div className="bar-content">
                    { navItems }
                </div>
                <div className="session-nav">
                    <a className="nav-link" href={`${process.env.REACT_APP_BASE_URL}/login`}><span>Login</span></a>
                    {/* <a className="nav-link" href={`${process.env.REACT_APP_BASE_URL}/register`}><span>Register</span></a> */}
                    <Link className='reg-btn' to="/what-you-get">Buy</Link>
                </div>
            </nav>
    };

    return (
        <div className="hf-container">
            <div className="site-menu-bar">
                <div className="logo-container">
                    <Link to="/"><img height="40" src="/assets/imgs/logo3.png" alt="logo"></img></Link>
                </div>
                { navbar }
            </div>
        </div>
    );
}

export default withRouter(NavBar);
