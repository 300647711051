import React, { useState, useEffect } from 'react';
import { trustedBy, testimonials } from '../../utils/data';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

 
//number of tests
const numInjuryTests = process.env.REACT_APP_INJURY_TESTS;
const numPerformanceTests = process.env.REACT_APP_PERFORMANCE_TESTS;

// The Home page from www.axgen.us. 
const Home = () => {
    const { width } = useSelector((state) => ({
        width: state.ui.width
    }));

    const [selected, selectAthlete] = useState("Kris Mendoza");

    // scroll to top on mount
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // creates the tabs that control what one can view at once
    // the selectAthlete function sets the selected athlete as the state, allowing the react to key into the object and dynamically render the relevant info
    // for the tabs, only the first names are used, to reduce space, improve aesthetics, and not be too repetative (full name is already shown if one clicks on a tab)
    const athletesList = Object.keys(testimonials).map((name, idx) => {
        const nameArr = name.split(" ");
        return(
            <li className={selected === name ? "li-active" : "li-inactive"}
                key={idx}
                onClick={ () => selectAthlete(name) }
            ><h3>{nameArr[0]}</h3></li>
        )
    });

//"trusted by athletes" in data.js

    // const trustedByAthletes = trustedBy.map((el, idx) => (
    //     <li key={idx}><img className="mb-0" src={el["url"]} alt={el["title"]}></img></li>
    // ));

    return (
            <div className="home-parent">
                <div className="home-area">
                    <div className="home-img">
                        <h1>AxGen - Actionable Genetics</h1>
                        <div className="home-box"> 
                            {/* <h1><u>AxGen - Actionable Genetics</u></h1> */}
                            <p class="sports-link"><a href="http://axgen.us/sports"> AXGEN SPORTS</a>  provides exclusive genetic tests about your risk for injury, and a personalized plan of action to reduce those risks. In addition, AxGen provides tests for nutrition to optimize your supplementation.</p>
                        </div>
                        <div className="home-box">
                            <p class="health-link"><a href="http://axgen.us/health"> AXGEN HEALTH</a>  brings personalized medicine to medical professionals by offering exclusive, state of the art genetic tests for chronic diseases such as osteoporosis, coronary heart disease, type 2 diabetes and many more. Genetic testing from AxGen serves as an early warning system for your patients for common and preventable diseases.</p>
                        </div>
                    </div>
                    <div className="home-img-filter"></div>
                </div>

                {/* Creates DNA Helix Code */}

                {/* <div className="homepage-section" id="our-partners-area">
                    <div className="features-content">
                        <div className="row">
                            <div className="section-title mx-auto mb-0">
                                {/* <h1><span>Trusted By Athletes Competing In</span></h1> */}
                            {/* </div>
                        </div> */}
                        {/* <div className="testimonials-parent">
                            <ul className="trusted-by">
                                { trustedByAthletes }
                            </ul>
                        </div> */}
                {/* //     </div> */}
                {/* // </div> */} 
                {/* <div className="homepage-section" id="our-partners-area"> */} 
                    {/* {/* <div className="features-content"> */}
                        {/* <div className="row">
                            <div className="section-title mx-auto mb-5">
                                <h1><span>Trusted By Athletes Competing In</span></h1>
                            </div>
                        </div>
                        <div className="testimonials-parent">
                            <ul className="trusted-by">
                                { trustedByAthletes }
                            </ul>
                        </div>
                    </div> */}
                {/* </div> */}
            {/* <div className="homepage-section color" id="features-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>The AxGen Approach</span></h1>
                        </div>
                    </div>
                    <div>
                        <img className="feat-img" alt="" src="./assets/imgs/axgen_banner.jpg"></img>
                        <div className="features-text">
                            <h2>PERSONALIZED</h2>
                            <p>Your genetic makeup influences whether you are at increased risk for injury. Don’t train with a cookie-cutter approach, make a training plan that is uniquely suited to your own DNA.</p>
                        </div>
                        <div className="features-text">
                            <h2>SCIENCE-BACKED</h2>
                            <p>AxGen only tests for injury markers that are backed by rigorous science. Led by world renowned geneticist Dr. Stuart Kim, we conducted the research behind many of our tests at Stanford University. We give you results that you know you can trust.</p>
                        </div>
                        <div className="features-text">
                            <h2>ACTIONABLE</h2>
                            <p>The true value of an AxGen test comes from pairing our DNA insights with the correct training exercises to make sure you stay healthy and competing. Each AxGen test comes with an injury prevention plan with selected exercises from our robust video library.</p>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="homepage-section" id="tests-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>AxGen Tests</span></h1>
                        </div>
                    </div>
                    { width > 767 ? (
                        <div className="tests-section d-flex">
                            <div className="tests-text">
                                <h2>KNOW YOUR RISKS</h2>
                                <p>AxGen tests for {numInjuryTests} sports injuries (including ACL and PCL injury, Achilles tendon rupture, stress fracture, and concussion) and {numPerformanceTests} nutrition and performance markers (including ibuprofen sensitivity and testosterone level).</p>
                                <p className="mb-0"><strong>Click <Link to="/what-you-get">here</Link> to discover more.</strong></p>
                            </div>
                            <div className="img-container">
                                <img alt="" src="./assets/imgs/axgen_ipad.jpg"></img>
                            </div>
                        </div>
                        ) : (
                        <div className="tests-section">
                            <h2 className="text-center">KNOW YOUR RISKS</h2>
                            <img alt="" src="./assets/imgs/axgen_ipad.jpg"></img>
                            <p>AxGen tests for {numInjuryTests} sports injuries (including ACL and PCL injury, Achilles tendon rupture, stress fracture, and concussion) and {numPerformanceTests} nutrition and performance markers (including ibuprofen sensitivity and testosterone level).</p>
                            <p className="mb-0"><strong>Click <Link to="/what-you-get">here</Link> to discover more.</strong></p>
                        </div> 
                        )
                    }
                </div>
            </div> */}
            {/* <div className="homepage-section color" id="testimonials-area">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1><span>Testimonials</span></h1>
                        </div>
                    </div>
                    <div className="testimonials-parent">
                        <ul className="image-selection-menu">
                            { athletesList }
                        </ul>
                        <div className="testimonial-content">
                            <div className="testimonial-img-container">
                                <img src={testimonials[selected]["img"]} alt=""></img>
                            </div>
                            <div className="testimonial-text">
                                <div className="speech-bubble">
                                    <p>{ testimonials[selected]["text"] }</p>
                                    { testimonials[selected]["text2"] ? <p>{ testimonials[selected]["text2"] }</p>
                                    : null
                                        }
                                    <h3>{selected}</h3>
                                    <p className="testimonial-title">{testimonials[selected]["title"]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="homepage-section" id="process-section">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            <h1>Our Process</h1>
                        </div>
                    </div>
                    <div className="works-container">
                        <div className="line-img"></div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-img-1.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>1</span>
                            </div>
                            <div className="item-p">
                                <h2>ORDER</h2>
                                <p>Create an AxGen account under the "Register" tab. <a href={`${process.env.REACT_APP_BASE_URL}/buy`}>Purchase</a> an AxGen DNA kit or a DNA file upload. Your kit will arrive in the mail in 3-5 business days. For file uploads, simply upload your existing 23andMe or Ancestry DNA file via our secure web application.</p>
                            </div>
                        </div>
                        <div className='right-works'>
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-2.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <span>2</span>
                                <hr></hr>
                            </div>
                            <div className="item-p">
                                <h2>COLLECT SAMPLE</h2>
                                <p>Once you receive your kit, <a href={`${process.env.REACT_APP_BASE_URL}/activate`}>activate</a> your kit's barcode on our website. Then follow the enclosed instructions to collect a saliva sample and send the kit back to us with prepaid shipping.</p>
                            </div>
                        </div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-3.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>3</span>
                            </div>
                            <div className="item-p">
                                <h2>PROCESSING</h2>
                                <p>AxGen uses a process called genotyping to create genomic data from your sample.  We then pass your genotype through our proprietary algorithms to generate genomic insights unique to your own DNA.</p>
                            </div>
                        </div>
                        <div className='right-works'>
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/pro-cess-4.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <span>4</span>
                                <hr></hr>
                            </div>
                            <div className="item-p">
                                <h2>DISCOVER</h2>
                                <p>Once results are ready in 3-4 weeks, you'll be able to access your comprehensive results profile via our secure web app. Your personalized report will contain: 1) genetic insights about your injury risks + performance & nutrition markers, 2) specific actions you can take based on your genetic insights and 3) an exercise video library tailored to your unique results.</p>
                            </div>
                        </div>
                        <div className="left-works">
                            <div className="item-header">
                                <span>
                                    <img src="./assets/imgs/process-5.png" alt=""></img>
                                </span>
                            </div>
                            <div className="step-title">
                                <hr></hr>
                                <span>5</span>
                            </div>
                            <div className="item-p">
                                <h2>ACT</h2>
                                <p>Once you review your results dashboard, it is time to optimize your training for injury prevention and performance. To help you achieve this we offer add-on consultations with 1) an AxGen Sports Genetics Advisor to help you better understand your genetic results and 2) an AxGen Sports Genetics Trainer to tailor your training program to your unique genetic profile.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="homepage-section1">
                <div className="features-content">
                    <div className="row">
                        <div className="section-title mx-auto mb-5">
                            {/* <h1><span>Partnering with AxGen</span></h1> */}
                        </div>
                    </div>
                    <div>
                        {/* <p>AxGen has formed partnerships with <a href="https://www.axgen.us/about">teams, sports medicine clinics and training centers</a>  to help their athletes stay healthy.</p>
                        <p>AxGen has formed partnerships with <a href="http://localhost:3000/about">teams, sports medicine clinics and training centers</a>  to help their athletes stay healthy.</p>
                        <p>Partners receive an AxGen portal to enlist their athletes and keep track of their data.</p>
                        <p>Email <a href="mailto:contact@axgen.us?subject=Partnership Inquiry">contact@axgen.us</a> for more information and pricing.</p> */}
                    </div>

                </div>
                {
                    // width > 768 && <div className="spacer"></div>
                }
            </div>
        </div>
    );
}
export default Home;
