import React, { useEffect } from 'react';
import { research } from '../../utils/data';

//The science tab.
const Science = () => {
    const scienceCards = Object.keys(research).map((title, idx) => <ResearchCard key={idx} title={title} article={research[title]} />);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="content-parent-bg">
            <header className="top-hero">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1 className="mb-0">OUR SCIENCE</h1>
                    </div>
                </div>
            </header>
            <div className="content-container">
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>GENETIC TESTING</span></h2>
                    </div>
                    <div className="content-text">
                        <ul className="list-list mb-0">
                            <li>All saliva samples are processed in CLIA-certified and CAP-accredited labs</li>
                            <li>Our kit is manufactured in accordance with FDA's Good Manufacturing Practice regulations</li>
                            <li>Genotyping is a well-established and reliable platform for analyzing DNA</li>
                            <li>Our reports are developed by scientific and medical experts</li>
                        </ul>
                    </div>
                </section>
                <section className="content-section mx-0">
                    <div className='section-header'>
                        <h2><span>THE SCIENCE</span></h2>
                    </div>
                    <div className="content-text">
                        <p>Our genetic tests have strict requirements for rigor and scientific reliability. Some genetic markers derive from candidate gene studies, where scientists make guesses about which genes are involved. Other markers are from genome-wide screens, where every gene is assayed to find those with the strongest effect.</p>
                        <p>AxGen only uses markers showing an association that is genome-wide significant (i.e. p-values less than 5 x 10<sup>-8</sup>). DNA markers identified in genome-wide association studies are much more reliable than those from candidate gene studies. This is because genome-wide association studies screen the entire genome for the strongest SNP rather than testing just a few candidate genes, and because DNA markers that are genome-wide significant have p-values that are 10<sup>6</sup> times smaller (i.e. stronger) than those from candidate gene studies.</p>
                        <p>Until 2015, genetic markers for musculoskeletal injuries were based entirely on candidate gene studies (<a href="/assets/pdfs/the-dawning-age.pdf">Goodlin et al., 2015</a>). In candidate gene studies, one or a few genes are targeted for analysis based on their known biological functions. Candidate gene studies have a long history of being unreliable, not only for sports injuries but for many diseases and traits (<a href="https://pubmed.ncbi.nlm.nih.gov/11600885/">Ioannidis et al., 2001</a>). The problem with candidate gene studies is that the statistical cut-off for an association is weak p &lt;0.05 compared to p &lt; 5 x 10<sup>-8</sup> for genome-wide association studies) and their cohort sizes are small (several hundred participants compared to &gt;10,000 participants in genome-wide association studies). For instance, initial studies reported variants in 14 candidate genes to be associated with Achilles tendinopathy and 6 candidate genes with an association with ACL rupture (<a href="/assets/pdfs/the-dawning-age.pdf">Goodlin et al., 2015</a>). However, none of these variants were validated in a subsequent study using &gt;100,000 participants (<a href="/assets/pdfs/gwas-achilles-acl.pdf">Kim et al., 2017</a>), therefore we did not add either of these tests to our lineup. Other commercial DNA testing companies rely on results from candidate gene studies, but care should be taken as these reports may be unreliable.</p>
                        <p className="mb-0">Many of AxGen’s tests are based on recent research from Stanford University. This research used data from two large cohorts: the Research Program in Genes, Environment and Health (102,000 subjects) and the UK Biobank (488,000 participants). Data from these large cohorts enabled the discovery of top genetic markers from the genome for the first time.</p>
                    </div>
                </section>
                <section className="content-section mx-0">
                    <div className='section-header'>
                        <h2><span>RESEARCH</span></h2>
                    </div>
                    <div className="research-section">
                        { scienceCards }
                    </div>
                </section>
            </div>
        </div>
    );
};

//research data imported from data.js
export const ResearchCard = ({ title, article }) => {
    return (
        <div className="card-container">
            <div className="card-img-wrapper">
                <a href={article['url']}>
                    <img src={article['img']} alt={title}></img>
                </a>
            </div>
            <div className="card-content">
                <div className="card-text">
                    <p>{article['authors']}</p>
                    <a href={article['url']}><strong>{title}</strong></a>
                </div>
                <div className="card-fter">
                    <hr/>
                    <p className="card-footer-link" >
                        <a href={article['url']}>{article['journal']}</a>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Science;