import React from 'react';

// accepts a banner (bool) and a numItems (number of items in banner)
// returns an inline content wrapper class meant to handly a dynamic number of banners
// banner could have a variable number of items; each banner item is assumed to have a height of 30px and fixed combined top and bottom padding of 16px; plus a 2px offset
// mobile styles is based on window width rather than state width
export const getContentWrapperStyle = (banner, numItems) => {
    if (banner) {
      const baseStyles = {
        width: "100%",
        minHeight: "inherit"
      };
      let margTop;
      if (window.innerwidth > 767) {
       margTop = (numItems * 30) + 16 + 61 + 2;
       baseStyles["marginTop"] = `${margTop}px`;
      } else {
        margTop = (numItems * 47) + 16 + 56 + 2;        
        baseStyles["marginTop"] = `${margTop}px`;
      };
      return baseStyles;
    } else {
      // inline styles that manage the banner 
      const contentWrapperBannerless = {
        "regular": {
          width: "100%",
          marginTop: "61px",
          minHeight: "inherit"
        },
        "mobile": {
          width: "100%",
          marginTop: "56px",
          minHeight: "inherit"
        }
      };
    return window.innerwidth > 767 ? contentWrapperBannerless["mobile"] : contentWrapperBannerless["regular"];
  };
};


// adds extra li elements for aesthetics for lists on pages
export const addExtraLi = (iterable, modulo) => {
  let remainder = modulo - (iterable.length % modulo);
  if (remainder !== 0) {
      let lastIdx = iterable.length - 1;
      let i = 0;
      while (i < remainder) {
          iterable.push(<li key={lastIdx += 1}></li>);
          i += 1;
      };
  };
};


// makes lists for podcasts and news media
export const _listMaker = (list, type) => {
    return Object.keys(list).map((title, idx) => {
        let listItemj = list[title];
        return (
            <li key={idx}>
                <a href={listItemj["link"]} target="_blank" rel="noopener noreferrer">
                    <h5>{title}</h5>
                </a>
                <p>{listItemj["text"]}</p>
                { type === "podcast" && listItemj.hasOwnProperty("embed") &&
                    <iframe 
                        title={title}
                        src={listItemj["embed"]}
                        frameBorder="0"
                        sandbox="allow-same-origin allow-scripts allow-forms allow-popups">
                    </iframe>
                }
            </li>
        );
    });
};
