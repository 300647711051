import React, { useState } from 'react';

//The FAQ component
const FAQ = ({ idx, question, content }) => {
    const [isOpen, open] = useState(false);

    const toggleFAQ = (e) => {
        e.preventDefault();
        if(isOpen) {
            open(false);
        } else {
            open(true);
        };
    };
    
    return (
        <div className={isOpen ? "card-current" : "card"}>
            <div className="card-header" onClick={(e) => toggleFAQ(e)}>
                <h5 className="mb-0">
                    <button className="faq6" type="button">
                        <span>Q.{idx + 1}</span>
                        <div>{question}</div>
                        <i className={isOpen ? "fas fa-minus" : "fas fa-plus"}></i>
                    </button>
                </h5>
            </div>
            {
                isOpen && 
                <div className="collapsed-faq">
                    <div className="card-body">
                        { content }
                    </div>
                </div>
            }
        </div>
    );
};

export default FAQ;