import React, { useEffect } from 'react';
import { hnews, hyoutube } from '../../utils/data';
import { _listMaker } from '../../utils/style-utils';

//the media page.
const MediaHealth = () => {
    // scroll to top on mount
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    
    // presentational = stuff you see on the page.
    //podcast and links to news are in data.js
    
    const newsList = _listMaker(hnews, "news");
    // const podcastList = _listMaker(healthpodcasts, "healthpodcast");
    const youtubeList = _listMaker(hyoutube, "youtube");
    // const bookList = _listMaker(books, "books");

    return (
        <div className="athletes-parent">
            <header className="top-hero">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1>Health Media</h1>
                        <p>AxGen in the News</p>
                    </div>
                </div>
            </header>
            <div className="content-container">
                {/* <section className="content-section pb-0">
                    <div className='section-header'>
                        <h2><span>PODCASTS</span></h2>
                    </div>
                    <div className="section-body">
                        <ul className="media-list">
                            { podcastList }
                        </ul>
                    </div>
                </section> */}
                                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>NEWS</span></h2>
                    </div>
                    <div className="section-body">
                        <ul className="media-list">
                            { newsList }
                        </ul>
                    </div>
                </section>
                <section className="content-section">
                    <div className='section-header'>
                        <h2><span>YOUTUBE</span></h2>
                    </div>
                    <div className="section-body">
                        <ul className="media-list">
                            { youtubeList }
                        </ul>
                    </div>
                </section>
                {/* <section className="content-section">
                    <div className='section-header'>
                        <h2><span>BOOKS</span></h2>
                    </div>
                    <div className="section-body">
                        <ul className="media-list">
                            { bookList }
                        </ul>
                    </div>
                </section> */}
            </div>
        </div>
    );
};

export default MediaHealth;