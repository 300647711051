import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { hposts } from '../../utils/data';
import { titleizer } from '../../utils/text';

//the blog page - overview of every post

export const Blog = ({ history }) => {
    const [curPost, setCurPost] = useState("");
    
    useEffect(() => {
        window.scrollTo(0,0);
        if (curPost !== "") {
            setCurPost("");
        };
    }, []);
    useEffect(() => {
        if (curPost !== "") {
            history.push(`/blog/${curPost}`);
        };
    }, [curPost]);

    const handleReadMoreClick = (url) => {
        window.open(url, '_blank'); // Opens the URL in a new tab
    };

//list of the blog posts. 
    const blogPosts = Object.keys(hposts).map((title, idx) => {
        let post = hposts[title];
        return <BlogCard key={title}  post={post} title={title} setCurPost={setCurPost} handleReadMoreClick={handleReadMoreClick}   />
    });

    return (
        <div className="content-parent-bg">
            <header className="top-hero">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1 className="mb-0">BLOG</h1>
                    </div>
                </div>
            </header>
            <div className="content-container">
                <section className="content-section mx-0">
                    <div className="blog-posts">
                        { blogPosts }
                    </div>
                </section>
            </div>
        </div>
    );
};

//formats each of the card. 
const BlogCard = ({ handleReadMoreClick, setCurPost, post, title }) => {
    return (
        <div className="blog-card-container col-md-6 col-lg-4 position-relative">
            <div className="blog-card">
                <div className="post-img">
                    {/* Use a parent element or JSX fragment */}
                    <div>
                        <img onClick={(e) => handleReadMoreClick(post["url"])} src={post['img']} alt={title} />
                    </div>
                </div>
                <div className="post-content">
                    <div className="post-text">
                        <div onClick={(e) => handleReadMoreClick(post["url"])}
                        className="btn-link">{titleizer(title)}</div>
                        <p>{post["summary"]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Blog);
