import React from 'react';
import { useSelector } from 'react-redux';
import { results } from '../../utils/data';
import ReactTooltip from 'react-tooltip';

const numInjuryTests = process.env.REACT_APP_INJURY_TESTS;
const numHealthTests = process.env.REACT_APP_HEALTH_TESTS;
const kitPrice = process.env.REACT_APP_KIT_PRICE;
const uploadPrice = process.env.REACT_APP_UPLOAD_PRICE;
// startIndex is the idx to start the Injury tests.
const startIndex = parseInt(numHealthTests);  // Convert strings to integers
// midIndex is the idx to start the Performance tests.
const midIndex = startIndex + parseInt(numInjuryTests);

//the Wnat you get page in web-site. Finds info in data.js.
const WhatYouGet = () => {
    const { width } = useSelector((state) => ({
        width: state.ui.width
    }));

    // scroll to top on mount
    React.useEffect(() => {
        window.scrollTo(0,0);
    }, []);    

    const cards = results.map((result, idx) => {
        console.log(`Card ${idx} - Title: ${result['title']}`);
        return (
          <ResultCard
            key={idx}
            width={width}
            color={result['color']}
            title={result['title']}
            risk={result['risk']}
            tag={result['tag']}
            tooltip={result['tooltip']}
          />
        );
      });
    const marg = width < 768 ? 'mx-0' : '';

    return (
        <div className="content-parent">
            <header className="top-hero">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1 className="mb-0">SEE WHAT AXGEN CAN OFFER YOU</h1>
                    </div>
                </div>
            </header>
            <div className="content-container">
                <section className="content-section">
                    <div className='section-header condensed'>
                        <h2><span>PERSONALIZED REPORT</span></h2>
                    </div>
                    <div className="content-text">
                        <p className="text-left">AxGen analyzes your genome with cutting-edge science to determine your personalized risk profile. You can purchase a kit for <b>${kitPrice}</b> or upload a 23andMe or Ancestry genome file for <b>${uploadPrice}</b>.</p>
                        <p className="text-left mb-0">You will receive a report like the one below. Hover over the cards to learn more about each test we offer.</p>
                    </div>
                    <div className="result-parent">
                        <div className="container py-4">
                            <div className="text-center">
                                <h2>Demo User's Genetic Results</h2>
                            </div>
                            <br></br>
                            <div className="title-break">
                                <span>Health</span>
                            </div>
                            <div className={`row ${marg} mt-4 d-flex`}>
                                { cards.slice(0, startIndex) }
                            </div>
                            <br></br>
                            <div className="title-break">
                                <span>Injuries</span>
                            </div>
                            <div className={`row ${marg} mt-4 d-flex`}>
                            { cards.slice(startIndex, midIndex) }
                            </div>
                            <br></br>
                            <div className="title-break">
                                <span>Nutrients and Performance</span>
                            </div>
                            <div className={`row ${marg} mt-4 d-flex`}>
                            { cards.slice(midIndex, cards.length) }
                            </div>
                        </div>
                    </div>
                    <div className="section-button">
                        <p>Want to get started?</p> 
                        <a href={`${process.env.REACT_APP_BASE_URL}/buy`}>Buy for yourself</a>
                    </div>
                    <div className="section-button">
                        <a href={`${process.env.REACT_APP_BASE_URL}/gift`}>Buy as a gift</a>
                    </div>
                </section>
            </div>
            <ReactTooltip place="top" className="tooltip-axgen" effect="solid" />
        </div>
    );
};

export const ResultCard = ({ width, color, title, risk, tag, tooltip }) => {
    const pad = width < 768 ? 'px-2' : '';
    const margY = width < 768 ? 'my-2' : 'my-3';
    const cardHeight = width < 768 ? 'min-vh-8' : 'min-vh-15';
    const valPadding = width < 992 ? 'mb-2' : "";
    const colStyle = width < 768 ? 'col-12' : 'col-4';

    return (
        <div className={`dashboard-item ${colStyle} ${pad} ${margY} ${cardHeight}`}>
          <div className={`card h-100 ${color}`} data-tip={tooltip}>
            <div className="text-center">
              <img alt="" src={"/assets/imgs/tests/" + tag + ".png"} className="result-icon" />
              <div className="mt-3">
                <strong className={`result-name ${valPadding}`}>
                  <span>{title}</span>
                </strong>
                <div className="result-value">
                  {risk}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default WhatYouGet;
